.textBlock {
  border: 1px solid #666;
  border-radius: 10px;

  background-color: #222;

  padding: 20px;

  position: relative;
}

.copy {
  position: absolute;

  top: 10px;
  right: 10px;

  background-color: #444;

  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid white;

  border-radius: 5px;

  opacity: .5;

  cursor: pointer;

  transition: .3s;
}

.copy.hidden {
  display: none;
}

.copy svg {
  fill: white;
}

.copy:hover {
  opacity: .7;
}

.copy.showTip:before {
  content: 'Copié !';
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #444;

  font-size: 12px;

  position: absolute;

  width: 50px;
  height: 20px;

  right: -65px;

  padding: 5px;
  
  border-radius: 5px;
}