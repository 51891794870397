.home {
  background-color: #111;
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.left, .right {
  width: 50%;
}

.home .pageContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  padding: 0 50px;
  width: calc(100% - 100px);
  gap: 100px;
}

.home h1 {
  margin: 20px 0;
}

.home .buttonsBar {
  margin: 20px 0;

  display: flex;
  flex-direction: row;

  gap: 20px;

  flex-wrap: wrap;
}

.home .modifyHours button,
.home .actions button,
.home .buttonsBar button {
  padding: 10px 20px;
  border: 1px solid #fff;
  outline: none;
  background-color: #191919;

  color: var(--text-color, #fff);

  border-radius: 10px;


  width: 250px;

  cursor: pointer;
}

.soinsHeader {
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.soinsHeader .entry {
  padding: 20px;

  border-bottom: 1px solid #666;

  transition: .3s;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.soinsHeader .entry svg {
  margin-left: 5px;

  margin-top: -2px;

  opacity: .3;

  transition: .3s;
}
.soinsHeader .entry svg:hover {
  opacity: .7;
}


.soinsHeader .entry:hover {
  border-color: #aaa;
}

.soinsHeader .entry.selected {
  border-color: #fff;
}

.home .hat {
  position: absolute;

  width: 75px;
  height: 75px;
  left: calc(50% + 48px);

  rotate: 30deg;
}

.home .snow {
  position: absolute;
  display: block;

  background: url(../assets/snow.gif);

  background-size: 20%;

  width: 100vw;
  height: 100vh;

  z-index: 100000;

  opacity: .7;

  pointer-events: none;

  opacity: .3;

  /* animation: snowDisapear 3s 2s forwards; */
}

.home .helper {
  position: absolute;
  top : 10px;
  left: 10px;
  
  padding: 5px 10px;

  border: 1px solid #fff;
  background-color: #333333;

  border-radius: 10px;

  font-size: 12px;
}

.home .helper span.link {
  cursor: pointer;
  text-decoration: underline;
}

.home .helper span.tipaction {
  cursor: pointer;
}

/* @keyframes snowDisapear {
  from {
    opacity: .7;
  }
  to {
    opacity: 0;
  }
} */