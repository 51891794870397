.soinsDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;

  margin-top: 20px;
}

.oneLiner {
  display: flex;
  flex-direction: row;

  gap: 20px;

  align-items: flex-end;
}

.priceInput input,
.soinsDataContainer input {
  padding: 5px;
  border: 1px solid #fff;
  outline: none;
  background-color: #191919;

  color: #fff;

  border-radius: 10px;
}

.reportInput {
  display: flex;
  flex-direction: column;
}

.reportInput span {
  padding: 5px;
  border: 1px solid #fff;
  outline: none;
  background-color: #191919;

  color: #fff;

  border-radius: 10px;

  width: 30vw;
}

.priceData {
  display: flex;
  flex-direction: column;

  align-items: flex-end;

  gap: 10px;

  margin-top: 20px;
}

.priceInput label {
  margin-right: 10px;
  position: relative;
}

.priceInput select {
  background-color: #191919;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 5px;
}

.soinsDataContainer .save {
  font-size: 15px;
  opacity: .7;
  cursor: pointer;

  transform: translateY(-25%);
}

.priceInput,
.priceInput .numberSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.linksToTrello {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: fixed;

  bottom: 20px;
  left: 20px;

  gap: 20px;
}

.linksToTrello a {
  color: #55c;
}